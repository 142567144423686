<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Grading report</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Grading Report
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enrolment key" dense outlined clearable v-model="search.exam_key"  v-on:keyup.enter="searchOnlineExamReportGradingReport"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="Exam view from date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchOnlineExamReportPending()"
                          @input="search.start_date = $event !== null ? $event : ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        no-title
                        @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="Exam view up to date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchOnlineExamReportPending()"
                          @input="search.end_date = $event !== null ? $event : ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        :min="search.start_date"
                        v-model="search.end_date"
                        no-title
                        @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn :loading="isLoading"
                         @click="searchOnlineExamReportGradingReport"
                         class="mt-1 btn btn-block btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Candidate Name</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Marked Date</th>
                  <th class="px-3">Result</th>

                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in grading_reports" :key="index">
                    <td class="px-3">
                      <a   class="font-weight-bolder">
                        {{item.full_name}}
                      </a>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.exam_key}}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.product_name}}
                      </div>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 font-weight-medium text-hover-primary mb-1 font-size-sm">
                        {{item.result_available_date_format}}
                      </a>
                    </td>
                    <td>
                      <div class="mb-1" v-if="item.mark_obtained">
                        {{item.mark_obtained}}/{{item.availableMarks ? item.availableMarks :'100'}}
                      </div>
                      <div class="mb-1" v-else>
                        N/A
                      </div>
                    </td>

                  </tr>
                  <tr v-if="grading_reports.length == 0">
                    <td colspan="5" class="text-center px-3">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="grading_reports.length > 0"
                            class="pull-right mt-7"
                            @input="getAllOngoingOfficialExams"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import OnlineExamMarkerService from "@/services/online-marker/OnlineExamMarkerService";

const onlineExamMarker=new OnlineExamMarkerService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  data(){
    return{
      search:{
        exam_key:'',
        start_date:'',
        end_date:'',
      },
      isLoading:false,
      grading_reports:[],
      dialog:false,
      loading:false,

      remarks:'',
      total: null,
      perPage: null,
      page: null,
      menu:false,
      menu1:false,
    }
  },
  methods:{
    getAllOngoingOfficialExams(){
      this.isLoading=true;
      onlineExamResult
          .getGradingReportOnlineExamResult(this.search, this.page)
          .then(response => {
            this.grading_reports=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    searchOnlineExamReportGradingReport(){
      this.getAllOngoingOfficialExams();
    },

  },
  mounted() {
    this.getAllOngoingOfficialExams();
  }
}
</script>